import React from 'react';

const CardTable = ({table}) => {
    return (
        <div className={"flex flex-col gap-1"}>
            {table.map((x, i) => {
                return (
                    <div key={i} className={`grid grid-cols-10 gap-1`}>
                        {x.content.map((y, j) => {
                            return (
                                <div key={j}
                                     className={`col-span-${y.span} ${i === 0 ? 'font-semibold bg-gradient-to-r from-violet-500 via-fuchsia-500 to-violet-500 animate-text' : 'bg-dark-gray'} p-0.5 rounded h-full text-zinc-600 dark:text-zinc-400`}>
                                    <div
                                        className={`${j === 0 ? 'font-semibold' : ''} text-xs h-full w-full justify-center py-0.5 text-center flex rounded bg-white dark:bg-[#1D1D1D] items-center`}>
                                        {y.text}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )
            })}
        </div>
    )
}

export default CardTable;