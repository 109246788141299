import React from "react";
import {ongoingProjects} from "../../content/projects";

const SemiStructuredInterview = () => {
    const project = ongoingProjects.filter(x => x.id === 'onboarding-swes-using-gen-ai')[0];
    return (
        <div className={"flex flex-col gap-6"}>
            <div className={"text-base"}>
                We interviewed 8 people to get insights about which features do Software engineers and managers find the most valuable during an onboarding process.
            </div>
            <div className={"grid grid-cols-2 gap-32"}>
                <div className={"col-span-1"}>
                    {/*<img src={semiStructuredInterview} alt={"Semi-structured Interview"} className={"w-full h-auto"}/>*/}
                    <div className={"flex flex-col col-span-1 gap-4"}>
                        {project.semiStructuredInterview.interview.map((x, i) => {
                            return (
                                <div key={i} className={"grid grid-cols-5"}>
                                    <div className={"col-span-1 rounded h-32 clip-arrow-down bg-gradient-to-r from-violet-500 via-fuchsia-500 to-violet-500 animate-text text-white font-semibold p-0.5"}>
                                        <div
                                            className={"h-full w-full px-3 py-6 text-center justify-center flex clip-arrow-down rounded bg-white dark:bg-[#1D1D1D] items-center"}>
                                            {x.title}
                                        </div>
                                    </div>
                                    <div
                                        className={"rounded col-span-4 h-24 bg-gradient-to-r from-violet-500 via-fuchsia-500 to-violet-500 animate-text p-0.5 pl-0"}>
                                        <ul className={"flex flex-col justify-center h-full rounded col-span-4 bg-white dark:bg-[#1D1D1D] text-base text-zinc-600 dark:text-zinc-400 list-disc pl-6 px-3"}>
                                            <li>Question: {x.question}</li>
                                            <li>Rationale: {x.rationale}</li>
                                        </ul>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>
                <div className={"col-span-1 flex flex-col"}>
                    <div className={"text-base"}>
                        This helped us:
                    </div>
                    {/*<img src={semiStructuredInterviewHelp} alt={"Semi-structured Interview Help"}*/}
                    {/*     className={"w-full h-auto"}/>*/}
                    <div
                        className="-mb-24 bg-gradient-to-r from-violet-500 via-fuchsia-500 to-violet-500 animate-text rounded-full w-56 h-56 flex items-center ml-24 self-center justify-center text-center text-white p-0.5">
                        <div
                            className={"h-full w-full p-4 flex flex-col rounded-full bg-white dark:bg-[#1D1D1D] items-center text-center justify-center"}>
                            <div className={"font-semibold text-lg "}>
                                Insight 1
                            </div>
                            <div>
                                Identify common onboarding challenges
                            </div>
                        </div>
                    </div>
                    <div
                        className="-mb-12 bg-gradient-to-r from-violet-500 via-fuchsia-500 to-violet-500 animate-text rounded-full w-48 h-48 flex items-center self-start justify-center text-center text-white p-0.5">
                        <div
                            className={"h-full w-full p-4 flex flex-col rounded-full bg-white dark:bg-[#1D1D1D] items-center text-center justify-center"}>
                            <div className={"font-semibold text-lg "}>
                                Insight 2
                            </div>
                            <div>
                                Measure AI's impact on onboarding time and ease
                            </div>
                        </div>
                    </div>
                    <div
                        className="bg-gradient-to-r from-violet-500 via-fuchsia-500 to-violet-500 animate-text rounded-full w-48 h-48 flex items-center self-center justify-center text-center text-white p-0.5">
                        <div
                            className={"h-full w-full p-4 flex flex-col rounded-full bg-white dark:bg-[#1D1D1D] items-center text-center justify-center"}>
                            <div className={"font-semibold text-lg "}>
                                Insight 3
                            </div>
                            <div>
                                Improve AI guidance based on user feedback
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SemiStructuredInterview;