import './App.css';
import Home from './pages/home';
import Header from "./components/header";
import Mentorship from "./pages/mentorship"
import Footer from "./components/footer";
import About from "./pages/about";
import Projects from "./pages/projects";
import Research from "./pages/research";
import {Route, Routes} from 'react-router-dom';
import ARNavigationSystem from "./pages/projects/ar-guided-safety-navigation-system";
import Onboarding from "./pages/projects/onboarding-swes-using-genai";
import AssistiveYoga from "./pages/projects/assistive-yoga-clothing";

function App() {
    return (
        <div className={"bg-black text-white"}>
            <Header />
            <Routes>
                <Route path="/" element={<Home />}/>
                <Route path="/about" element={<About />}/>
                <Route path={"/mentorship"}>
                    <Route path="/mentorship/articles/:id" element={<Mentorship/>}/>
                    <Route path="/mentorship" element={<Mentorship/>}/>
                </Route>
                {/*<Route path="/courses" element={<Courses />}/>*/}
                {/*<Route path="/designs" element={<Designs />}/>*/}
                <Route path="/projects/ar-guided-safety-navigation-system" element={<ARNavigationSystem />}/>
                <Route path="/projects/onboarding-swes-using-gen-ai" element={<Onboarding />}/>
                <Route path="/projects/assistive-yoga-clothing" element={<AssistiveYoga />}/>
                <Route path="/projects" element={<Projects />}/>
                <Route path="/research" element={<Research/>}/>
                {/*<Route path="/volunteering" element={<Volunteering />}/>*/}
            </Routes>
            <Footer />
        </div>
    )
}

export default App;
