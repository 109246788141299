import React from 'react';
import ishita from "../media/image0 (1).jpeg"
import {GitHub, Instagram, LinkedIn, YouTube} from "../media/svg";

const About = () => {
    return (
        <div className="sm:px-8 mt-16 sm:mt-32">
            <div className="mx-auto max-w-7xl lg:px-8">
                <div className="relative px-4 sm:px-8 lg:px-12">
                    <div className="mx-auto max-w-2xl lg:max-w-5xl">
                        <div className="grid grid-cols-1 gap-y-16 lg:grid-cols-2 lg:grid-rows-[auto_1fr] lg:gap-y-12">
                            <div className="lg:pl-20">
                                <div className="max-w-xs px-2.5 lg:max-w-none">
                                    <img alt="" loading="lazy" width="800" height="800" className="aspect-square rounded-2xl bg-zinc-100 object-top object-cover dark:bg-zinc-800"
                                         src={ishita} style={{color: "transparent"}} />
                                </div>
                            </div>
                            <div className="lg:order-first lg:row-span-2">
                                <h1 className="text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                                    I’m{" "}
                                    <span className='bg-gradient-to-r bg-clip-text text-transparent from-violet-500 via-fuchsia-500 to-violet-500 animate-text'>
                                        Ishita Datta
                                    </span>. A software developer and consultant based in Atlanta.
                                </h1>
                                <div className="mt-6 space-y-7 text-base text-zinc-600 dark:text-zinc-400">
                                    <p>
                                        My journey towards software development commenced during my school years and
                                        gained momentum as a computer science undergraduate specializing in machine
                                        intelligence and data science. Since then, I have ventured into various tech
                                        stacks, encompassing diverse areas such as app development, excel add-ins,
                                        database management systems, computer vision and machine learning models.
                                    </p>
                                    <p>
                                        In my current professional capacity, I actively refine my expertise in API
                                        development, microservices, and automation, focusing on cultivating a niche
                                        proficiency in cloud integrations and HR tech. My commitment lies in assisting
                                        clients, customers, and knowledge seekers by actively contributing to both the
                                        enterprise world as well as the developer community.
                                    </p>
                                </div>
                            </div>
                            <div className="lg:pl-20">
                                <ul>
                                    <li className="flex">
                                        <a className="group flex text-sm font-medium text-zinc-800 transition hover:text-purple-500 dark:text-zinc-200 dark:hover:text-purple-400" href={"https://www.linkedin.com/in/ishita-datta-44869296"}>
                                            <LinkedIn />
                                            <span className="ml-4">
                                                LinkedIn
                                            </span>
                                        </a>
                                    </li>
                                    <li className="mt-4 flex">
                                        <a className={"group flex text-sm font-medium text-zinc-800 transition hover:text-purple-500 dark:text-zinc-200 dark:hover:text-purple-400"} href={"https://github.com/ishitadatta"}>
                                            <GitHub />
                                            <span className="ml-4">
                                                GitHub
                                            </span>
                                        </a>
                                    </li>
                                    <li className="mt-4 flex">
                                        <a className={"group flex text-sm font-medium text-zinc-800 transition hover:text-purple-500 dark:text-zinc-200 dark:hover:text-purple-400"} href={"https://www.instagram.com/lifestylewithishita/"}>
                                            <Instagram />
                                            <span className="ml-4">
                                                Instagram
                                            </span>
                                        </a>
                                    </li>
                                    <li className="mt-4 flex">
                                        <a className={"group flex text-sm font-medium text-zinc-800 transition hover:text-purple-500 dark:text-zinc-200 dark:hover:text-purple-400"} href={"https://youtube.com/@amethystzeal9349"}>
                                            <YouTube />
                                            <span className="ml-4">
                                                YouTube
                                            </span>
                                        </a>
                                    </li>
                                    <li className="mt-8 border-t border-zinc-100 pt-8 dark:border-zinc-700/40 flex">
                                        <a className="group flex text-sm font-medium text-zinc-800 transition hover:text-purple-500 dark:text-zinc-200 dark:hover:text-purple-400" href="mailto:isheeta50@gmail.com">
                                            <svg viewBox="0 0 24 24" aria-hidden="true" className="h-6 w-6 flex-none fill-zinc-500 transition group-hover:fill-purple-500">
                                                <path fillRule="evenodd" d="M6 5a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H6Zm.245 2.187a.75.75 0 0 0-.99 1.126l6.25 5.5a.75.75 0 0 0 .99 0l6.25-5.5a.75.75 0 0 0-.99-1.126L12 12.251 6.245 7.187Z"></path>
                                            </svg>
                                            <span className="ml-4">
                                                isheeta50@gmail.com
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;