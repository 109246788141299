import React from 'react';
import {articles} from "../content/articles";
import {courses} from "../content/courses";
import {useParams} from "react-router-dom";
import ArticleEngine from "../components/articleEngine";
import ta from "../media/ta.png";
import MentorshipCard from "../components/mentorshipCard";
import Carousel from "../components/carousel";

const Mentorship = () => {
    const {id} = useParams();

    if (typeof id === 'undefined')
        return (
            <div className="sm:px-8 mt-16 sm:mt-32">
                <div className="mx-auto max-w-7xl lg:px-8">
                    <div className="relative px-4 sm:px-8 lg:px-12">
                        <div className="mx-auto max-w-2xl lg:max-w-5xl">
                            <header className="max-w-2xl">
                                <h1 className="text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                                    <span className='bg-gradient-to-r bg-clip-text text-transparent from-violet-500 via-fuchsia-500 to-violet-500 animate-text'>
                                        Sharing Knowledge
                                    </span> and Empowering Minds – Through Teaching, Writing, and Guiding
                                </h1>
                            </header>
                            <div className={"flex flex-col gap-4 justify-start"}>
                                <div className={"mt-16 sm:mt-20"}>
                                    <div className={"grid grid-cols-7 gap-10 flex-wrap md:flex-nowrap"}>
                                        <div className={"col-span-3"}>
                                            <img alt="" loading="lazy" className="rounded-lg bg-zinc-100 dark:bg-zinc-800 w-full"
                                                 src={ta} style={{color: "transparent"}} data-nimg={"1"}
                                            />
                                        </div>
                                        <div className={"flex flex-col gap-4 col-span-4"}>
                                            <span className='text-3xl font-semibold bg-gradient-to-r bg-clip-text text-transparent from-violet-500 via-fuchsia-500 to-violet-500 animate-text'>
                                                Graduate Teaching Assistant
                                            </span>
                                            <div className={"col-span-4"}>
                                                I am currently working as a Graduate Teaching Assistant for the Natural
                                                Language Processing course at Georgia Institute of Technology. This role is
                                                particularly exciting to me as I implement diverse machine learning models to
                                                process and analyze various types of textual data, deriving meaningful
                                                predictions and insights.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-16 sm:mt-20 flex flex-col gap-8">
                                    <div className={"flex flex-row gap-4 text-3xl font-semibold"}>
                                        <span className={`bg-gradient-to-r bg-clip-text text-transparent from-violet-500 via-fuchsia-500 to-violet-500 animate-text`}>
                                            Articles
                                        </span>
                                        {/*<span className={section === 'articles' ? 'hidden' : 'flex cursor-pointer'} onClick={() => setSection('articles')}>*/}
                                        {/*    Articles*/}
                                        {/*</span>*/}
                                        {/*<span>*/}
                                        {/*    /*/}
                                        {/*</span>*/}
                                        {/*<span className={`bg-gradient-to-r bg-clip-text text-transparent from-violet-500 via-fuchsia-500 to-violet-500 animate-text ${section === 'courses' ? 'flex' : 'hidden'}`}>*/}
                                        {/*    Courses*/}
                                        {/*</span>*/}
                                        {/*    <span className={section === 'courses' ? 'hidden' : 'flex cursor-pointer'} onClick={() => setSection('courses')}>*/}
                                        {/*    Courses*/}
                                        {/*</span>*/}
                                    </div>
                                    <p className="-mt-6 text-base text-zinc-600 dark:text-zinc-400">
                                        All my long-form thoughts on the software industry, PaaS offerings, and programming.
                                    </p>
                                    <Carousel items={articles}/>
                                </div>
                                <div className="mt-16 sm:mt-20 flex flex-col gap-8">
                                    <div className={"flex flex-row gap-4 text-3xl font-semibold"}>
                                        <span className={`bg-gradient-to-r bg-clip-text text-transparent from-violet-500 via-fuchsia-500 to-violet-500 animate-text`}>
                                            Course Instructor
                                        </span>
                                    </div>
                                    <p className="-mt-6 text-base text-zinc-600 dark:text-zinc-400">
                                        Knowledge is meant to be distributed. I have published a few courses that are <i> free of cost </i> ! This is for everyone - both beginners and experts - who want to learn something new.
                                    </p>
                                    <div className={`flex-wrap flex-row md:grid md:grid-cols-2 gap-16 justify-between`}>
                                        {courses.map((course, i) => {
                                            return (
                                                <MentorshipCard
                                                    key={i}
                                                    title={course.name}
                                                    description={course.description}
                                                    link={course.link}
                                                    cta={"View course"}
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    const article = articles.filter(x => x.id === id)[0];
    return (
        <div className="sm:px-8 mt-16 lg:mt-32">
            <div className="mx-auto max-w-7xl lg:px-8">
                <div className="relative px-4 sm:px-8 lg:px-12">
                    <div className="mx-auto max-w-2xl lg:max-w-5xl">
                        <div className="xl:relative">
                            <div className="mx-auto max-w-2xl">
                                <button type="button" aria-label="Go back to articles" className="group mb-8 flex h-10 w-10 items-center justify-center rounded-full bg-white shadow-md shadow-zinc-800/5 ring-1 ring-zinc-900/5 transition dark:border dark:border-zinc-700/50 dark:bg-zinc-800 dark:ring-0 dark:ring-white/10 dark:hover:border-zinc-700 dark:hover:ring-white/20 lg:absolute lg:-left-5 lg:-mt-2 lg:mb-0 xl:-top-1.5 xl:left-0 xl:mt-0">
                                    <svg viewBox="0 0 16 16" fill="none" aria-hidden="true" className="h-4 w-4 stroke-zinc-500 transition group-hover:stroke-zinc-700 dark:stroke-zinc-500 dark:group-hover:stroke-zinc-400">
                                        <path d="M7.25 11.25 3.75 8m0 0 3.5-3.25M3.75 8h8.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                                <article>
                                    <header className="flex flex-col">
                                        <h1 className="mt-6 text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                                            {article.name}
                                        </h1>
                                        <time dateTime={article.date} className="order-first flex items-center text-base text-zinc-400 dark:text-zinc-500">
                                            <span className="h-4 w-0.5 rounded-full bg-zinc-200 dark:bg-zinc-500"/>
                                            <span className="ml-3">
                                                {article.dateName}
                                            </span>
                                        </time>
                                    </header>
                                    <div className="mt-8 prose dark:prose-invert flex flex-col gap-5">
                                        {article.content.map((x, i) => {
                                            return <ArticleEngine key={i} content={x} />
                                        })}
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Mentorship;