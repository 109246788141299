import React, {useState} from 'react';
import {ongoingProjects} from "../../content/projects";
import Modal from "../../components/modal";
import HoverAnimation from "../../components/hoverAnimation";

const AssistiveYoga = () => {
    const project = ongoingProjects.filter(x => x.id === 'assistive-yoga-clothing')[0];

    const [isModalOpen, setIsModalOpen] = useState(new Array(project.ideation.length).fill(false));
    const [storyboard, setStoryboard] = useState(0);

    const openModal = (index) => {
        let temp = new Array(project.ideation.length).fill(false);
        temp[index] = true;
        setIsModalOpen(temp);
    }

    const closeModal = () => {
        setIsModalOpen(new Array(project.ideation.length).fill(false));
    }


    return (
        <div className="sm:px-8 mt-16 sm:mt-32">
            <div className="mx-auto max-w-7xl lg:px-8">
                <div className="relative px-4 sm:px-8 lg:px-12">
                    <div className="mx-auto max-w-2xl lg:max-w-5xl">
                        <header className="max-w-2xl">
                            <h1 className="text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                                <span
                                    className='bg-gradient-to-r bg-clip-text text-transparent from-violet-500 via-fuchsia-500 to-violet-500 animate-text'>
                                    {project.name}
                                </span>
                            </h1>
                        </header>

                        <div className={"flex flex-col gap-8 justify-start"}>
                            <div className="mt-8 sm:mt-10 flex flex-col gap-8">
                                <h2 className="text-3xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-3xl">
                                    <span>
                                        Problem Statement
                                    </span>
                                </h2>
                                <div className={"grid grid-cols-1 gap-8"}>
                                    <div className={"flex flex-col gap-6 col-span-1"}>
                                        {project.problemStatement.map((x, i) => {
                                            return (
                                                <div key={i} className="text-base text-zinc-600 dark:text-zinc-400">
                                                    {x}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-8 sm:mt-10 flex flex-col gap-8">
                                <h2 className="text-3xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-3xl">
                                    <span>
                                        Observation and Landscape Analysis
                                    </span>
                                </h2>
                                <div className="space-y-6 flex flex-col gap-16">
                                    {project.observationAndLandscapeAnalysis.map((x, i) => {
                                        return (
                                            <div className={"grid grid-cols-2 gap-6 col-span-1"}>
                                                <div
                                                    className={`col-span-1 flex flex-col gap-8 ${i % 2 === 0 ? 'order-first' : 'order-last'}`}>
                                                    <h3 className="font-semibold text-xl">
                                                        {x.name}
                                                    </h3>
                                                    <div
                                                        className={"grid grid-cols-2 text-base text-zinc-600 dark:text-zinc-400 list-disc gap-6"}>
                                                        {x.content.map((y, j) => {
                                                            return (
                                                                <div key={j} className={"grid grid-cols-4 gap-2"}>
                                                                    <div
                                                                        className={"col-span-1 flex justify-center py-4"}>
                                                                        {y.icon}
                                                                    </div>
                                                                    <div className={"col-span-3"}>
                                                                        {y.text}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                </div>
                                                <div className={"col-span-1"}>
                                                    <img src={x.src} alt={x.name}/>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>

                        </div>

                        <div className={"flex flex-col gap-4 justify-start"}>
                            <div className={"mt-8 sm:mt-10 flex flex-col gap-8"}>
                                <h2 className="text-3xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-3xl">
                                    <span>
                                        Ideation
                                    </span>
                                </h2>
                                <div className={"grid grid-cols-5 justify-center gap-6"}>
                                    {project.ideation.map((x, i) => {
                                        return (
                                            <>
                                                <div
                                                    className={`cursor-pointer hover:scale-105 rounded-xl bg-gradient-to-r from-violet-500 via-fuchsia-500 to-violet-500 animate-text p-0.5`}
                                                    onClick={() => openModal(i)}>
                                                    <div
                                                        className={"h-full w-full p-4 rounded-xl bg-white dark:bg-[#1D1D1D] flex flex-col items-center gap-2"}>
                                                        <div
                                                            className={'rounded-lg flex bg-white h-[150px] w-[150px] max-h-[150px] justify-center items-center'}>
                                                            <img className={"h-full rounded-lg "} src={x.src}
                                                                 alt={x.name}/>
                                                        </div>
                                                        <div key={i}
                                                             className={"text-center justify-center flex flex-col text-white"}>
                                                            {x.name}
                                                        </div>
                                                    </div>
                                                </div>

                                                <Modal isOpen={isModalOpen[i]} onClose={closeModal} title={x.name}>
                                                    <div className={"grid grid-cols-2 gap-6 col-span-1"}>
                                                        <div className={`col-span-1 flex flex-col gap-8`}>
                                                            <ul className={"flex flex-col justify-center text-base text-zinc-600 dark:text-zinc-400 list-disc gap-6 h-full"}>
                                                                {x.content.map((y, j) => {
                                                                    return (
                                                                        <li key={j} className={""}>
                                                                            {y}
                                                                        </li>
                                                                    )
                                                                })
                                                                }
                                                            </ul>
                                                        </div>
                                                        <div className={"col-span-1"}>
                                                            <img src={x.src} alt={x.name}/>
                                                        </div>
                                                    </div>
                                                </Modal>
                                            </>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                        </div>

                        <div className={"flex flex-col gap-4 justify-start"}>
                            <div className={"mt-8 sm:mt-10 flex flex-col gap-8"}>
                                <h2 className="text-3xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-3xl">
                                    <span>
                                        Storyboards
                                    </span>
                                </h2>
                                <div className={"flex gap-4"}>
                                    {project.storyboard.map((x, i) => {
                                        return (
                                            <h3 className={`font-semibold text-lg rounded-full px-2 ${storyboard === i ? 'bg-gradient-to-r from-violet-500 via-fuchsia-500 to-violet-500 animate-text' : 'cursor-pointer'}`} onClick={() => setStoryboard(i)}>
                                                {x.name}
                                            </h3>
                                        )
                                    })}
                                </div>
                                <div className="flex flex-col gap-10">
                                    {project.storyboard.map((x, i) => {
                                        return (
                                            <div key={i} className={`${storyboard === i ? 'flex' : 'hidden'} flex-col gap-4 w-full`}>

                                                <div className={"grid grid-cols-3 w-full gap-6 bg-white p-4 "}>
                                                    {x.content.map((y, j) => {
                                                        return (
                                                            <div key={j}
                                                                 className={`col-span-1 flex flex-col gap-4 items-center`}>
                                                                <div
                                                                    className={"flex justify-center items-center bg-white w-[200px] h-[200px]"}>
                                                                    <img className={"max-h-[200px]"} src={y.src}
                                                                         alt={y.name}/>
                                                                </div>
                                                                <div
                                                                    className={"text-sm text-zinc-600 list-disc gap-6"}>
                                                                    <b>{y.name}:</b>{y.text}
                                                                </div>
                                                            </div>
                                                        )
                                                    })}


                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className={"flex flex-col gap-4 justify-start"}>
                            <div className={"mt-8 sm:mt-10 flex flex-col gap-8"}>
                                <h3 className="text-2xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-3xl">
                                    <span
                                        className='bg-gradient-to-r bg-clip-text text-transparent from-violet-500 via-fuchsia-500 to-violet-500 animate-text'>
                                        Final Prototype
                                    </span>
                                </h3>
                                <div className="flex flex-col gap-16">
                                    <div className={"grid grid-cols-2 gap-4"}>
                                        <div className={"flex flex-col col-span-1 gap-4"}>
                                            <h3 className="font-semibold text-xl sm:text-2xl">
                                                Design Direction
                                            </h3>
                                            <div className={"flex flex-col gap-4"}>
                                                {project.designDirection.map((x, i) => {
                                                    return (
                                                        <HoverAnimation opacity={0.7} key={i}
                                                                        className={"rounded-xl bg-dark-gray"}>
                                                            <HoverAnimation opacity={0.35}
                                                                className="m-0.5 p-3 rounded-xl bg-white dark:bg-[#1D1D1D]">
                                                                <div key={i} className={"flex flex-col gap-2"}>
                                                                    <h3 className={"font-medium text-base"}>
                                                                        {x.name}
                                                                    </h3>
                                                                    <ul className={"text-base text-zinc-600 dark:text-zinc-400 list-disc px-3"}>
                                                                        {x.content.map((y, j) => {
                                                                            return (
                                                                                <li key={j} className={""}>
                                                                                    {y}
                                                                                </li>
                                                                            )
                                                                        })}
                                                                    </ul>
                                                                </div>
                                                            </HoverAnimation>
                                                        </HoverAnimation>
                                                    )
                                                })}
                                            </div>
                                        </div>

                                        <div className="flex flex-col gap-4 col-span-1">
                                            <h3 className="font-semibold text-xl sm:text-2xl">
                                                Design Justification
                                            </h3>
                                            <div className={"flex flex-col gap-4"}>
                                                {project.designJustification.map((x, i) => {
                                                    return (
                                                        <HoverAnimation opacity={0.7} key={i}
                                                                        className={"rounded-xl bg-dark-gray"}>
                                                            <HoverAnimation opacity={0.35}
                                                                className="m-0.5 p-3 rounded-xl bg-white dark:bg-[#1D1D1D]">
                                                                <div key={i} className={"flex flex-col gap-4"}>
                                                                    <h3 className={"font-medium text-base"}>
                                                                        {x.feature}
                                                                    </h3>
                                                                    <div
                                                                        className={"text-base text-zinc-600 dark:text-zinc-400"}>
                                                                        {x.justification}
                                                                    </div>
                                                                </div>
                                                            </HoverAnimation>
                                                        </HoverAnimation>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex flex-col gap-8">
                                        <h3 className="font-semibold text-xl sm:text-2xl">
                                            Key Features
                                        </h3>
                                        <div className="grid grid-cols-2 gap-8">
                                            {project.keyFeatures.map((x, i) => {
                                                return (
                                                    <div
                                                        className="bg-gradient-to-r from-violet-500 via-fuchsia-500 to-violet-500 animate-text rounded-xl flex items-center justify-center p-0.5">
                                                        <div
                                                            className="h-full w-full p-4 flex flex-col rounded-xl bg-white dark:bg-[#1D1D1D] gap-2">
                                                            <h3 className="font-semibold text-xl">
                                                                {x.name}
                                                            </h3>
                                                            <ul className="text-base text-zinc-600 dark:text-zinc-400 list-disc pl-6">
                                                                {x.content.map((y, j) => {
                                                                    return (
                                                                        <li key={j}>{y}</li>
                                                                    )
                                                                })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>

                                    <div className="flex flex-col gap-8">
                                        <h3 className="font-semibold text-xl sm:text-2xl">
                                            Hardware Components
                                        </h3>
                                        <div className={"grid grid-cols-3 gap-6 col-span-1"}>
                                            <div
                                                className={`col-span-2 h-full justify-center flex flex-col text-base text-zinc-600 dark:text-zinc-400 gap-4`}>
                                                {project.hardwareComponents.content.map((x, i) => {
                                                    return (
                                                        <div key={i} className={"flex flex-col gap-1"}>
                                                            <div className={"text-white font-semibold"}>
                                                                {x.name}
                                                            </div>
                                                            <div>
                                                                {x.text}
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div className={"col-span-1"}>
                                                <img src={project.hardwareComponents.src} alt={"Hardware Components"}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex flex-col gap-8">
                                        <h3 className="font-semibold text-xl sm:text-2xl">
                                            Manner of Interaction
                                        </h3>
                                        <div className={"grid grid-cols-4 gap-6 col-span-1"}>
                                            <div className={"col-span-1 flex flex-col"}>
                                                {project.interaction.src.map((x, i) => {
                                                    return (
                                                        <img key={i} src={x} alt={"Hardware Components"}/>
                                                    )
                                                })}
                                            </div>
                                            <div
                                                className={`col-span-3 h-full justify-center flex flex-col text-base text-zinc-600 dark:text-zinc-400 gap-4`}>
                                                {project.interaction.content.map((x, i) => {
                                                    return (
                                                        <HoverAnimation opacity={0.7} key={i}
                                                                        className={"rounded-xl bg-dark-gray"}>
                                                            <HoverAnimation opacity={0.35}
                                                                className="m-0.5 p-3 rounded-xl bg-white dark:bg-[#1D1D1D]">
                                                                <div key={i} className={"grid grid-cols-10 gap-1"}>
                                                                    <div
                                                                        className={"col-span-2 h-full flex items-center justify-center text-center text-white font-semibold"}>
                                                                        {x.name}
                                                                    </div>
                                                                    <div
                                                                        className={"col-span-8 flex flex-col gap-1 text-xs"}>
                                                                        {x.content.map((y, j) => {
                                                                            return (
                                                                                <div key={j}>
                                                                                    <span
                                                                                        className={"text-white"}>{y.name}: </span>{y.text}
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </HoverAnimation>
                                                        </HoverAnimation>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex flex-col gap-8">
                                        <h3 className="font-semibold text-xl sm:text-2xl">
                                            Prototype Scaling and Feasibility Analysis
                                        </h3>
                                        <div
                                            className={"grid grid-cols-2 text-base text-zinc-600 dark:text-zinc-400 list-disc gap-6"}>
                                            {project.scaling.map((y, j) => {
                                                return (
                                                    <div key={j} className={"flex flex-col gap-2"}>
                                                        <div
                                                            className={"flex justify-center py-4"}>
                                                            {y.icon}
                                                        </div>
                                                        <div className={"flex justify-center font-semibold"}>
                                                            {y.name}
                                                        </div>
                                                        <div className={"text-base"}>
                                                            {y.text}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AssistiveYoga;