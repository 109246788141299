import React, {useState} from 'react';
import {recentProjects} from "../../content/projects";
import Modal from "../../components/modal";
import CardTable from "../../components/cardTable";

const Onboarding = () => {
    const project = recentProjects.filter(x => x.id === 'onboarding-swes-using-gen-ai')[0];

    const [isModalOpen, setIsModalOpen] = useState(new Array(project.researchMethods.length).fill(false));

    const openModal = (index) => {
        let temp = new Array(project.researchMethods.length).fill(false);
        temp[index] = true;
        setIsModalOpen(temp);
    }

    const closeModal = () => {
        setIsModalOpen(new Array(project.researchMethods.length).fill(false));
    }


    return (
        <div className="sm:px-8 mt-16 sm:mt-32">
            <div className="mx-auto max-w-7xl lg:px-8">
                <div className="relative px-4 sm:px-8 lg:px-12">
                    <div className="mx-auto max-w-2xl lg:max-w-5xl">
                        <header className="max-w-2xl">
                            <h1 className="text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                                <span
                                    className='bg-gradient-to-r bg-clip-text text-transparent from-violet-500 via-fuchsia-500 to-violet-500 animate-text'>
                                    {project.name}
                                </span>
                            </h1>
                        </header>

                        <div className={"flex flex-col gap-8 justify-start"}>
                            <div className="mt-8 sm:mt-10 flex flex-col gap-8">
                                <h2 className="text-3xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-3xl">
                                    <span>
                                        Problem Statement
                                    </span>
                                </h2>
                                <div className={"grid grid-cols-1 gap-8"}>
                                    <div className={"flex flex-col gap-6 col-span-1"}>
                                        {project.problemStatement.map((x, i) => {
                                            return (
                                                <div key={i} className="text-base text-zinc-600 dark:text-zinc-400">
                                                    {x}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="flex h-full items-center justify-around gap-16">
                                <div
                                    className="bg-gradient-to-r from-violet-500 via-fuchsia-500 to-violet-500 animate-text rounded-full w-48 h-48 flex items-center justify-center text-center text-white font-bold text-lg p-0.5">
                                    <div className={"h-full w-full p-4 flex rounded-full bg-white dark:bg-[#1D1D1D] items-center"}>
                                        Significance of Addressing this Problem
                                    </div>
                                </div>
                                <div className="space-y-6 flex flex-col">
                                    {project.significance.map((x, i) => {
                                        return (
                                            <div className="items-center">
                                                <h3 className="font-semibold text-xl">
                                                    {x.title}
                                                </h3>
                                                <ul className="text-base text-zinc-600 dark:text-zinc-400 list-disc pl-6">
                                                    {x.content.map((y, j) => {
                                                        return (
                                                            <li key={j}>{y}</li>
                                                        )
                                                    })
                                                    }
                                                </ul>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className={`grid grid-cols-5 gap-4 py-4`}>
                                {project.process.map((x, i) => {
                                    return (
                                        <div key={i} className={"flex flex-col col-span-1 gap-4"}>
                                            <div
                                                 className={"rounded h-16 clip-arrow bg-gradient-to-r from-violet-500 via-fuchsia-500 to-violet-500 animate-text text-white font-semibold p-0.5"}>
                                                <div className={"h-full w-full px-3 flex clip-arrow rounded bg-white dark:bg-[#1D1D1D] items-center"}>
                                                    {x.title}
                                                </div>
                                            </div>
                                            <ul className={"text-base text-zinc-600 dark:text-zinc-400 list-disc pl-6"}>
                                                {x.content.map((y, j) => {
                                                    return (
                                                        <li key={j}>{y}</li>
                                                    )
                                                })}
                                            </ul>
                                        </div>

                                    )
                                })}
                            </div>
                        </div>

                        <div className={"flex flex-col gap-4 justify-start"}>
                            <div className={"mt-8 sm:mt-10 flex flex-col gap-8"}>
                                <h2 className="text-3xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-3xl">
                                    <span>
                                        Research Methods
                                    </span>
                                </h2>
                                <div className={"grid grid-cols-3 justify-center gap-6"}>
                                    {project.researchMethods.map((x, i) => {
                                        return (
                                            <>
                                                <div
                                                    className={`${typeof x.component === 'undefined' ? "" : "cursor-pointer hover:scale-105"} h-36 rounded-xl bg-gradient-to-r from-violet-500 via-fuchsia-500 to-violet-500 animate-text p-0.5`}
                                                    onClick={() => openModal(i)}>
                                                    <div className={"h-full p-4 rounded-xl bg-white dark:bg-[#1D1D1D]"}>
                                                        <div key={i}
                                                             className={"h-full text-center justify-center text-xl flex flex-col gap-6 col-span-1 text-white px-2 py-0.5"}>
                                                            {x.name}
                                                        </div>
                                                    </div>
                                                </div>

                                                <Modal isOpen={isModalOpen[i]} onClose={closeModal} title={x.name}>
                                                    {x.component}
                                                </Modal>
                                            </>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                        </div>

                        <div className={"flex flex-col gap-4 justify-start"}>
                            <div className={"mt-8 sm:mt-10 flex flex-col gap-8"}>
                                <h2 className="text-3xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-3xl">
                                    <span>
                                        User Needs and Design Implications
                                    </span>
                                </h2>
                                <div className={"grid grid-cols-1 gap-8"}>
                                    <div className={"flex flex-col gap-6 col-span-1"}>
                                        <table className={"border-collapse table-auto"}>
                                            <thead>
                                            <tr className={"text-violet-500"}>
                                                <th className={"border-b border-zinc-600 dark:border-zinc-600 text-base p-4"}>
                                                    Findings
                                                </th>
                                                <th className={"border-b border-zinc-600 dark:border-zinc-600 text-base p-4"}>
                                                    User Needs
                                                </th>
                                                <th className={"border-b border-zinc-600 dark:border-zinc-600 text-base p-4"}>
                                                    Design Implications
                                                </th>
                                            </tr>
                                            </thead>
                                            {project.designImplications.map((x, i) => {
                                                return (
                                                    <tr>
                                                        <td className={"border-b border-zinc-600 dark:border-zinc-600 text-base text-zinc-600 dark:text-zinc-400 p-4"}>
                                                            {x.finding}
                                                        </td>
                                                        <td className={"border-b border-zinc-600 dark:border-zinc-600 text-base text-zinc-600 dark:text-zinc-400 p-4"}>
                                                            {x.userNeed}
                                                        </td>
                                                        <td className={"border-b border-zinc-600 dark:border-zinc-600 text-base text-zinc-600 dark:text-zinc-400 p-4"}>
                                                            {x.designImplication}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"flex flex-col gap-4 justify-start"}>
                            <div className={"mt-8 sm:mt-10 flex flex-col gap-8"}>
                                <h2 className="text-3xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-3xl">
                                    <span>
                                        User Journey Map
                                    </span>
                                </h2>
                                <div className={`grid grid-cols-10 gap-4 py-4`}>
                                    {project.journeyMap.categories.map((x, i) => {
                                        return (
                                            <div key={i} className={`${i === 0 ? 'col-start-2' : ''} flex flex-col col-span-${x.count} gap-4`}>
                                                <div
                                                    className={"text-xs rounded h-16 clip-arrow bg-gradient-to-r from-violet-500 via-fuchsia-500 to-violet-500 animate-text text-white font-semibold p-0.5"}>
                                                    <div
                                                        className={"h-full w-full px-3 flex clip-arrow rounded bg-white dark:bg-[#1D1D1D] items-center"}>
                                                        {x.title}
                                                    </div>
                                                </div>
                                            </div>

                                        )
                                    })}
                                </div>
                                <CardTable table={project.journeyMap.table} />
                            </div>
                        </div>

                        {/*<div className={"flex flex-col gap-4 justify-start"}>*/}
                        {/*    <div className={"mt-8 sm:mt-10 flex flex-col gap-8"}>*/}
                        {/*        <h2 className="text-3xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-3xl">*/}
                        {/*            <span>*/}
                        {/*                Storyboard*/}
                        {/*            </span>*/}
                        {/*        </h2>*/}
                        {/*        <div className={"flex flex-col gap-8"}>*/}
                        {/*            <div className={"flex flex-col gap-6 col-span-1"}>*/}
                        {/*                <p className={"text-base text-zinc-600 dark:text-zinc-400"}>*/}
                        {/*                    {project.storyboard.text}*/}
                        {/*                </p>*/}
                        {/*            </div>*/}
                        {/*            <div className={"flex flex-col gap-6 col-span-1"}>*/}
                        {/*                <img src={project.storyboard.img} alt={"Storyboard"} className={"w-full h-auto"}/>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Onboarding;