import React, {useState, useEffect} from 'react';

const HoverAnimation = ({opacity=0.1, className, children}) => {
    const [isHovered, setIsHovered] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [backgroundPosition, setBackgroundPosition] = useState({ x: 0, y: 0 });

    const handleMouseEnter = (e) => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleMouseMove = (e) => {
        if (isHovered) {
            const rect = e.currentTarget.getBoundingClientRect();
            if ((e.clientX - rect.left < 0) || (e.clientY - rect.top < 0))
                setIsHovered(false);
            else
                setPosition({ x: e.clientX - rect.left, y: e.clientY - rect.top });
        }
    };

    useEffect(() => {
        if (isHovered) {
            setBackgroundPosition({
                x: position.x, // Adjust the division factor for the desired speed
                y: position.y,
            });
        }
    }, [isHovered, position]);

    return (
        <>
            <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={`h-fit transition relative overflow-hidden ${className}`} onMouseMove={handleMouseMove}>
                {isHovered && (
                    <div className="absolute h-64 aspect-square bg-gradient-to-c from-violet-500 to-transparent to-70% -top-32 -left-32"
                        style={{
                            opacity: opacity,
                            transform: `translateX(${backgroundPosition.x}px) translateY(${backgroundPosition.y}px) translateZ(0)`,
                        }}
                    />
                )}
                {children}
            </div>
        </>
    )
}

export default HoverAnimation;